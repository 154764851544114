import "../core/src/modules/app-tabs-header/views/styles.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/modules/app-tabs-header/views/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61X7WrrOBD936cQgQsJRMFJmzRXpe+xsJRFtsaOtrYkJKVOdtl3XyzZsS1/4F4uARPL1syZM2dm5N1f+7IQxvII/fuEUMmZvRC0j6Ifb08IXYBnF0vQ8aBu1T3jRuX0TlCag1ugOc8E5hYKQ1ACwoKulqvHmHENieVSEKRlWS1nVBF09qbkF+g0lyVBSc5VtaIkr/Zj+AJhDUH0amW1jkuIP7nFVCmsIXMGmabZ29N/T7sH/D8ZtRRzg0sumCzN+yqluYHVh4tLUca4yLD28TgMnd1799IgOheGB1lHMBHv31djeXrHiRQWhCXIKJoAjsGWAOKtBUBQhPYn1Vo3F83FJ0HRWDSFZPC+okqtPlCA1WoqDPfsuqShaPdsto0fd9czeZgJcZipiTgbGnNI7UwmG9l4GXVB+LgUFwLY+8rqa5OgGTKe3QsxTT4zLa+CEfRF9RpjmqZcAOaJFNhAIgWj+r5566jY43ugmeAdoYLqjIspcbzM+4+lZqBxInOp57zXJTUT6HE8RRO5CA2PFVSjEA05tfwLAiXWhPjwqwV8CmI/OUiNq+e6DRRc4DrKZmnofZqwx4M2bR36BvEPistTaKm2M/Q4g4FnCzc76vNqQGMDOSSWICGFI6pOrKaMXw1BLz7QcVLHepSQeNCmjiigdl56J18vNKn89OplCbtK86IfZywr7VFW5am3yy/i/WbMfVOuMw31EI0j/16lH/2mirWMi+yxLeCsG0Xkfnt168cTht5x8up5H0q4Uxjn8dodSNMNtFoYqRQWl83AjKIZbRb01jjfnyO/PblqUwmWQUqvue1DJjk1FssU27uCMf77PJ7DEB0Yp/62TiHPuTLcjFdveeEWsBtjlZJLTdW3q8oxYvg/EGxw6zcTpIVcKhQoCGLS4VyGR6tmgeE5m/0UBMYCaj2Jnd0/J12OTK+lA2DQF5tHHce0d6o7TQzENk8LziZ9dityO9zSX+K2Z9F1mY9QDdOGHYMjVmNfJ8GhsmnubRunsZH51brVtpoQslLV/2JprSzqm7AnBNwOK2lpOoOKrrvK+PADwfrdlnCzHuZlizyNm5bHmAhp1wQKZe+brjheJjr4eJ7jwRDKuQCqcVaNSxB2fXiNGGTbhcMJHQ+78+nHFukspuvD8bhF7SXaRdHGET46nnq6+Z0Qw56GTsfd6/4B8uVli+rLDMhkVIbNt81UZYYDp5HtdwpguSiSsYNnxxJrB3emK23vF03Hyennjjz+GwKfp49VXQSdkwEw12p9NISmFnTdHOoSWa2mK9xV9T7q092cAGpIR3/XbQZ1nur3gpNhszp1JJtpffUwH4UaoutBXwJIKppwe2+62K/A87QDy+B9VbFTn+Eelp0Q3LdoKnVB/N+cWvhjXdE4Y82xW5vrMr3I9E9v+n9973vsvRAAAA==\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var dropIndicator = '_1wmnstie';
export var headerLeft = '_1wmnsti1';
export var labelIcon = '_1wmnstia _1wmnsti9';
export var pinSeparator = '_1wmnsti3';
export var root = '_1wmnsti0';
export var spacer = '_1wmnstid';
export var splitViewLabel = '_1wmnsti7';
export var splitViewLabelText = '_1wmnsti8';
export var splitViewSeparator = '_1wmnsti4';
export var tab = '_1wmnsti6';
export var tabCloseButton = '_1wmnstic _1wmnsti9';
export var tabCloseButtonWrapper = '_1wmnstib';
export var tabIcon = '_1wmnsti9';
export var tabWrapper = '_1wmnsti5';
export var tabs = '_1wmnsti2';